
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import AkkhorLayout from "../layout/akkhor";
import { useSelector } from "react-redux";
import AlertErrorForm from "../component/AlertErrorForm";
import Spinner from "../component/Spinner";
import Axios from "axios";
import { URL_LIST_ALL } from "../configs/api";
import { Link, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import SearchBoxModal from "../component/SearchBoxModal";
import { checkRole } from "../model";
import SerchBoxMulti from "../component/SerchBoxMulti";

export default function FormPhanCongCapSo() {
    const { user } = useSelector((state) => state.auth);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const rolesAllCompany = checkRole(user, 'list-users', 'viewallcompany');
    const rolesAll = checkRole(user, 'list-users', 'viewall');

    const defaultdetail = {
        company_id: '', author_id: user.data.id, shortname: '', title: '', vanthu: '', list_master: '', status: 1,
    }
    useEffect(
        function () {
            if (id > 0) {
                let mounted = true;
                Axios.get(URL_LIST_ALL + "parts/" + id, user.config)
                    .then(async (res) => {
                        if (res.data.status === "success") {
                            if (mounted) {
                                let detail = res.data.data
                                detail.list_master = JSON.parse(detail.list_master)
                                setDetail(detail);
                            }
                        } else console.log(res.data.message);
                    })
                    .catch((err) => console.log(err));
                return () => (mounted = false);
            }
        }, [user, id, refresh]
    );
    const formik = useFormik({
        initialValues: detail ? detail : defaultdetail,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            title: Yup.string().trim().nullable().required('Nhập tên phòng ban').max(150, "Độ dài không quá 150 ký tự")
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            setLoading(true)
            if (id > 0) {
                Axios.put(URL_LIST_ALL + 'parts/' + id, values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn cập nhật phòng ban thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            } else {
                // console.log(values);
                Axios.post(URL_LIST_ALL + 'parts', values, user.config).then(res => {
                    setLoading(false);
                    setSubmitting(false);
                    setRefresh(!refresh)
                    if (res.data.status === "success") {
                        resetForm();
                        confirmAlert({
                            title: 'Thành công',
                            message: 'Bạn thêm mới phòng ban thành công',
                            buttons: [{ label: 'OK' }]
                        });
                    } else {
                        confirmAlert({
                            title: 'Lỗi',
                            message: res.data.message,
                            buttons: [{ label: 'OK' }]
                        });
                    }
                }).catch(err => console.log(err));
            }
        },
    });

    return (
        <AkkhorLayout idrole="phan-cong-cap-so">
            <div className="breadcrumbs-area">
                <ul>
                    <li>
                        <Link to="/">Trang chủ</Link>
                    </li>
                    <li>
                        <Link to="/phan-cong-cap-so">Phân công cấp số</Link>
                    </li>
                    <li>{id ? 'Phân công cấp số' : 'Thêm mới Phân công cấp số'}</li>
                </ul>
            </div>
            <div className="card height-auto mt-4">
                <div className="card-body">
                    <div className="heading-layout1">
                        <div className="item-title">
                            <h3>Phân công cấp số</h3>
                        </div>
                    </div>
                    <form className="new-added-form" method="post" onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12 col-sm-6 form-group">
                                <label>Tên phòng ban <span className="text-danger">*</span></label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("title")} autoComplete="off" disabled/>
                                <AlertErrorForm formik={formik} name="title"></AlertErrorForm>
                            </div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Tên rút gọn</label>
                                <input type="text" placeholder="" className="form-control height32" {...formik.getFieldProps("shortname")} autoComplete="off" disabled />

                            </div>
                            <div className="col-xs-12"></div>

                            {rolesAllCompany || rolesAll ?
                                <div className="col-12 col-sm-6 form-group">
                                    <label>Công ty </label>
                                    <SearchBoxModal apiname={"company"} placeholder="Chọn công ty" colvalue={'id'} colname={'title'} defaultValue={formik.values['company_id']} name='company_id'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('company_id', e)
                                        }} disabled></SearchBoxModal>
                                    <AlertErrorForm formik={formik} name="company_id"></AlertErrorForm>
                                </div>
                                :
                                <input type="hidden" placeholder="" className="form-control height32" {...formik.getFieldProps("company_id")} />
                            }
                            <div className="col-12 col-sm-6 form-group">
                                <label>Trạng thái</label>
                                <select className="form-select height32" {...formik.getFieldProps("status")} disabled>
                                    <option value="0">Khoá</option>
                                    <option value="1">Hoạt động</option>
                                </select>
                            </div>
                            <div className="col-xs-12"></div>
                            <div className="col-12 col-sm-6 form-group">
                                <label>Người quản lý: </label>
                                <SerchBoxMulti apiname={"users"} placeholder="Chọn nhân sự" colvalue={'code'} colname={'fullname'} defaultValue={formik.values['list_master']} name='list_master'
                                    onChange={async (e) => {
                                        await formik.setFieldValue('list_master', e)
                                    }} disabled></SerchBoxMulti>
                            </div>
                            {id > 0 &&
                                <div className="col-12 col-sm-6 form-group">
                                    <label>Người cấp số văn thư:</label>
                                    <SearchBoxModal apiname={"users"} alldata={"?part_id="+id} placeholder="Chọn nhân sự" colvalue={'id'} colname={'fullname'} defaultValue={formik.values['vanthu']} name='vanthu'
                                        onChange={async (e) => {
                                            await formik.setFieldValue('vanthu', e)
                                        }}></SearchBoxModal>

                                </div>
                            }

                            <div className="col-12 form-group mg-t-8">
                                <button type="submit" disabled={formik.isSubmitting} className="btn-fill-lg btn-gradient-yellow btn-hover-bluedark">{loading ? <Spinner /> : <>Lưu lại</>}</button>
                                <button type="reset" onClick={formik.resetForm} className="btn-fill-lg bg-blue-dark btn-hover-yellow">Nhập lại</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </AkkhorLayout>
    );
}
