export const TYPE_ACCOUNT = [
  "Quản trị viên",
  "Nhân viên"
];
export const ID_TYPE_ACCOUNT = {
  QUAN_TRI: 0,
  GIAO_VIEN: 1,
  HOC_SINH: 2,
  PHU_HUYNH: 3,
}
export const LIST_TABLE = [
  "schools",
  "grades",
  "class",
  "students",
  "subjects",
  "timetable",
  "users",
  "years",
];
export const LIST_ALPHA = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
export const LIST_NATION = [
  "Kinh",
  "Tày",
  "Thái",
  "Mường ",
  "Khmer",
  "Hoa ",
  "Nùng  ",
  "Mông",
  "Dao",
  "Gia Rai",
  "Ê  Đê",
  "Ba Na",
  "Sán Chay ",
  "Chăm ",
  "Cơ Ho",
  "Xơ Đăng",
  "Sán Dìu",
  "Hrê",
  "RaGlay",
  "Mnông",
  "Thổ",
  "Xtiêng",
  "Khơ mú",
  "Bru Vân Kiều",
  "Cơ Tu",
  "Giáy",
  "Tà Ôi",
  "Mạ",
  "Giẻ-Triêng",
  "Co",
  "Chơ Ro",
  "Xinh Mun",
  "Hà Nhì",
  "Chu Ru",
  "Lào",
  "La Chí",
  "Kháng",
  "Phù Lá",
  "La Hủ",
  "La Ha",
  "Pà Thẻn",
  "Lự",
  "Ngái",
  "Chứt",
  "Lô Lô",
  "Mảng",
  "Cơ Lao",
  "Bố Y",
  "Cống",
  "Si La",
  "Pu Péo",
  "Rơ Măm",
  "Brâu",
  "Ơ Đu",
];
export const COLORS_CHECK = [
  "#999",
  "#21c83d",
  "#efd732",
  "#fd7a23",
  "#db0d18",
];

export const RolesRight = {
  
  dashboard: { title: "Bảng điều khiển", checked: false },
  
  "list-users": {
    title: "Quản lý tài khoản",
    table: "users",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      upload: { title: "Upload", checked: false },
      // reset: { title: "Đặt mật khẩu", checked: false },
      export: { title: "Xuất dữ liệu", checked: false },
      parts: { title: "Bộ phận", checked: false },
      roles: { title: "Phân quyền", checked: false },
      login: { title: "Đăng nhập", checked: false },
      leave: { title: "Ngày phép", checked: false },
      viewallpart: { title: "Quản lý theo phòng ban", checked: false },
      viewallcompany: { title: "Quản lý theo công ty", checked: false },
      viewall: { title: "Quản lý tất cả", checked: false },
    },
  },
  "baocaocongviec": {
    title: "Báo cáo công việc từ MyBHS",
    checked: false,
    children: {
      view: { title: "Xem", checked: false },
      viewuser: { title: "Xem cấp dưới", checked: false },
      viewpart: { title: "Xem theo phòng ban", checked: false },
      viewmypart: { title: "Xem theo phòng ban của tôi", checked: false },
      viewcompany: { title: "Xem theo công ty", checked: false },
      viewmycompany: { title: "Xem theo công ty của tôi", checked: false },
      viewall: { title: "Xem tất cả", checked: false }
    },
  },
  "users_login":{
    title: "Theo dõi đăng nhập",
    checked: false,
  },
  "list-thuc-tap-sinh": {
    title: "Quản lý thực tập sinh",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      upload: { title: "Upload", checked: false },
      // reset: { title: "Đặt mật khẩu", checked: false },
      export: { title: "Xuất dữ liệu", checked: false },
      parts: { title: "Bộ phận", checked: false },
      roles: { title: "Phân quyền", checked: false },
      login: { title: "Đăng nhập", checked: false },
      leave: { title: "Ngày phép", checked: false },
      viewallpart: { title: "Quản lý theo phòng ban", checked: false },
      viewallcompany: { title: "Quản lý theo công ty", checked: false },
      viewall: { title: "Quản lý tất cả", checked: false },
    },
  },
  "list-roles": {
    title: "Quản lý quyền hạn",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },

  "list-parts": {
    title: "Quản lý phòng ban",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      // member: { title: "Nhân sự", checked: false },
      viewallcompany: { title: "Xem theo công ty", checked: false },
      viewall: { title: "Xem tất cả", checked: false },
    },
  },
  "list-chucvu": {
    title: "Danh sách chức vụ",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      viewallcompany: { title: "Xem theo công ty", checked: false },
      viewall: { title: "Xem tất cả", checked: false },
    },
  },
  "list-company": {
    title: "Quản lý danh sách công ty",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-users_onday": {
    title: "Bảng chấm công chi tiết",
    checked: false,
    children: {
      upload: { title: "Upload", checked: false },
      view: { title: "Xem", checked: false },
      edit: { title: "Sửa", checked: false },
      viewallpart: { title: "Xem theo phòng ban", checked: false },
      viewallcompany: { title: "Xem theo công ty", checked: false },
      viewall: { title: "Xem tất cả", checked: false },
     
    },
  },
  "list-cham-cong-thang": {
    title: "Xem bảng chấm công tháng",
    checked: false,
    children: {
      view: { title: "Xem", checked: false },
      viewallpart: { title: "Xem theo phòng ban", checked: false },
      viewallcompany: { title: "Xem theo công ty", checked: false },
      viewall: { title: "Xem tất cả", checked: false },
      export: { title: "Xuất bảng chấm công", checked: false },
      chotcong: { title: "Chốt bảng chấm công", checked: false },
    },
  },
  "list-users-break": {
    title: "Đăng ký xin nghỉ/đi muộn/về sớm",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      viewallpart: { title: "Xem theo phòng ban", checked: false },
      viewallcompany: { title: "Xem theo công ty", checked: false },
      viewall: { title: "Xem tất cả", checked: false },
      export: { title: "Xuất", checked: false },
      editcheck: { title: "Sửa người duyệt", checked: false },
    },
  },
  "dang-ky-lam-le": {
    title: "Đăng ký đi làm ngày lễ/Cuối tuần",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      viewallpart: { title: "Xem theo phòng ban", checked: false },
      viewallcompany: { title: "Xem theo công ty", checked: false },
      viewall: { title: "Xem tất cả", checked: false },
    },
  },
  "duyet-dang-ky-lam-le": {
    title: "Phê duyệt đăng ký đi làm ngày lễ/Cuối tuần",
    checked: false,
    children: {
      viewallpart: { title: "Xem theo phòng ban", checked: false },
      viewallcompany: { title: "Xem theo công ty", checked: false },
      approve: { title: "Phê duyệt", checked: false },
    },
  },
  "list-approve-break": {
    title: "Phê duyệt xin nghỉ/đi muộn/về sớm",
    checked: false,
    children: {
      viewallpart: { title: "Xem theo phòng ban", checked: false },
      viewallcompany: { title: "Xem theo công ty", checked: false },
      approve: { title: "Phê duyệt", checked: false },
    },
  },
  "list-giaitrinh": {
    title: "Giải trình công",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      editcheck: { title: "Sửa người duyệt", checked: false },
      delete: { title: "Xoá", checked: false },
      reopen: { title: "Mở lại", checked: false },
      export: { title: "Xuất", checked: false },
      viewallpart: { title: "Xem theo phòng ban", checked: false },
      viewallcompany: { title: "Xem theo công ty", checked: false },
      viewall: { title: "Xem tất cả", checked: false },
    },
  },
  "list-duyet-giai-trinh": {
    title: "Phê duyệt giải trình công",
    checked: false,
    children: {
      approve: { title: "Phê duyệt", checked: false },
    },
  },
  "danh-sach-dang-ky-lam-online": {
    title: "Đăng ký làm online",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      viewallpart: { title: "Xem theo phòng ban", checked: false },
      viewallcompany: { title: "Xem theo công ty", checked: false },
      viewall: { title: "Xem tất cả", checked: false },
    },
  },
  "danh-sach-phe-duyet-lam-online": {
    title: "Phê duyệt làm online",
    checked: false,
    children: {
      approve: { title: "Phê duyệt", checked: false },
    },
  },
 
  "list-settings": {
    title: "Quản lý cài đặt thông số",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-users_onday_settings": {
    title: "Cài đặt giờ chấm công",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "cai-dat-nhan-su": {
    title: "Cài đặt nhân sự",
    checked: false,
  },
  "ngay-le": {
    title: "Ngày lễ trong năm",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "lambu": {
    title: "Quản lý ngày làm bù",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },

  "list-ca-lam": {
    title: "Ca làm việc",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "quan-ly-ca": {
    title: "Quản lý ca làm việc",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "phan-ca-lam": {
    title: "Phân ca làm việc",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "danh-sach-gui-mail": {
    title: "Hệ thống gửi mail",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
     
    },
  },
  "test-email": {
    title: "Test mail",
    checked: false,
  },
  "users-customizer": {
    title: "Tùy biến form nhân sự",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },

  "danh-muc-tin": {
    title: "Quản lý danh mục quy trình nội bộ",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      viewallcompany: { title: "Xem theo công ty", checked: false },
      viewall: { title: "Xem tất cả", checked: false },
    },
  },
  "mau-hop-dong": {
    title: "Quản lý mẫu hợp đồng",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "hop-dong": {
    title: "Quản lý hợp đồng",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "van-thu": {
    title: "Quản lý Văn thư lưu trữ",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      export: { title: "Xuất dữ liệu", checked: false },
    },
  },
  "vanthu_param": {
    title: "Phân loại Văn thư lưu trữ",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "phan-cong-cap-so": {
    title: "Phân công cấp số",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "booking-xe": {
    title: "Đặt xe",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      approve: {title: "Phê duyệt", checked: false }
    },
  },
  "booking-room": {
    title: "Đặt phòng họp",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      approve: {title: "Phê duyệt", checked: false }
    },
  },
  "list-room": {
    title: "Quản lý phòng họp",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "list-cars": {
    title: "Quản lý xe",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
    },
  },
  "danh-sach-tin": {
    title: "Quản lý danh sách quy trình nội bộ",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false },
      viewallcompany: { title: "Xem theo công ty", checked: false },
      viewall: { title: "Xem tất cả", checked: false },
    },
  },
  "users_new": {
    title: "Quản lý ứng viên",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false }
    },
  },
  "users_gitiho": {
    title: "Quản lý tài khoản Gitiho",
    checked: false,
    children: {
      add: { title: "Thêm", checked: false },
      edit: { title: "Sửa", checked: false },
      delete: { title: "Xoá", checked: false }
    },
  },
  "bangchotcongthang": {
    title: "Bảng chốt công tháng",
    checked: false,
  },
  "cham-cong-app": {
    title: "Chấm công Trên App",
    checked: false,
  },
  "upload-chamcong-bpmax": {
    title: "Chấm công BPMAX",
    checked: false,
  },
  "logs": {
    title: "Lịch sử người dùng",
    checked: false,
  },
};


export const CONFIG_EDITOR = {

  toolbar: {
    items: [
      'heading', 'MathType', 'ChemType',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      'imageUpload',
      'mediaEmbed',
      'insertTable',
      'blockQuote',
      'undo',
      'redo'
    ]
  },
}
export const CONFIG_STEP_TEST_1 = ['Thông tin phiếu bài', 'Lựa chọn câu hỏi', 'Chọn học sinh']
export const CONFIG_STEP_TEST_2 = ['Thông tin đề kiểm tra', 'Lựa chọn câu hỏi', 'Phát đề kiểm tra']
