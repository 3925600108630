
import React, { useState } from "react";
import { Link } from "react-router-dom";
import SRList from "../component/List";
import AkkhorLayout from "../layout/akkhor";

export default function PhanCongCapSo() {
    const [params, setParam] = useState('orderby=vanthu-desc');
    const [keyword, setKeyword] = useState('');
    const [filterShow, setFilterShow] = useState(false)

    const handleSubmit = (e) => {
        e.preventDefault();
        let _param = ''
        if (keyword !== '') _param += "&key=" + keyword
        setParam(_param);
    };
    let columns = [
        { name: "id", label: "ID", options: { filter: false, sort: true } },
        { name: "title", label: "Tên phòng ban" },
        { name: "company", label: "Thuộc công ty" },
        {
            name: "vanthu_name", label: "Người cấp số",
            options: {
                filter: false, sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    if (value) {
                        return <div style={{ minWidth: 200 }}>
                            Họ tên: <strong>{value}</strong><br />
                            MSNV: <strong>{tableMeta.vanthu_code}</strong><br />
                        </div>
                    } else {
                        return null
                    }
                },
            }
        },
        { name: "datecreate", label: "Ngày tạo" }
    ];


    return (<AkkhorLayout idrole="phan-cong-cap-so">
        <div className="breadcrumbs-area">
            <ul>
                <li>
                    <Link to="/">Trang chủ</Link>
                </li>

                <li>Phân công cấp số văn thư</li>
            </ul>
        </div>
        <div className="card mb-3">
            <div className="card-header">
                <div className="d-flex">
                    <div className="mr-auto">
                        <h3 className="title">Tìm kiếm</h3>
                    </div>
                    <button className="btn btn-sm btn-defaul" onClick={() => setFilterShow(!filterShow)}><span className={!filterShow ? "fas fa-angle-down" : "fas fa-angle-up"}></span></button>
                </div>
            </div>
            <div className={filterShow ? "card-body" : 'hidden-filter'}>
                <form className="new-added-form" method="get" >
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 form-group">
                            <label>Từ khoá </label>
                            <input type="text" placeholder="Tìm theo từ khoá" value={keyword} onChange={(e) => setKeyword(e.target.value)} className="form-control height32" autoComplete="off" />
                        </div>

                        <div className="col-12 ">
                            <button type="submit" onClick={handleSubmit} className="btn-fill-sm btn-gradient-yellow btn-hover-bluedark">Lọc</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <SRList name="parts" linkaction={"phan-cong-cap-so"} idrole="phan-cong-cap-so" params={params} title="Phòng ban" defaultLimit={50} serverSide={true} columns={columns}></SRList>
    </AkkhorLayout>);
}
